import { appContext } from '../context/ContextProvider';
import React, { useContext, useState, useEffect } from 'react';
import ConformationModal from '../components/ConformationModal'
import 'react-toastify/dist/ReactToastify.css';
import ReactModal from 'react-modal';
import { baseUrl } from '../utils/Constants';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';

function AdminUsers(props) {

  const { user } = useContext(appContext);
  const [roles, setRoles] = useState([]);
  const [roleId, setRoleId] = useState(null);
  const [users, setUsers] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const getRoles = () => {
    toast("Fetching roles!");

    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
    };

    fetch(`${baseUrl}/roles`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status) {
          result.data.length > 0 && setRoleId(result.data[0].id);
          setRoles(result.data);
        } else {
          alert('fetch error')
        }
      })
      .catch(error => {
        console.log('error', error);
      });
  }

  const getAdminUsers = () => {
    toast("Fetching Users!");

    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
    };

    fetch(`${baseUrl}/admin_users?q=admin&s=user_category&relations=roles`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status) {
          setUsers(result.data);
        } else {
          alert('fetch error')
        }
      })
      .catch(error => {
        console.log('error', error);
      });
  }


  const toggleStatus = (e, user_id, index) => {
    // e.preventDefault();

    var data = {
      "user_id": user_id
    };

    var requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
      redirect: 'follow'
    };

    fetch(`${baseUrl}/user/toggle/status`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status) {

          toast("Successfully Updated!");
        } else {
          console.log('message : ', result.message);
        }
      })
      .catch(error => console.log('error', error));

  }

  const updateData = (e) => {
    e.preventDefault();

    // console.log('users[selectedIndex].isPaid  ',users[selectedIndex].isPaid);

    setShowEditModal(false);

    var data = {
      "user_id": users[selectedIndex].id,
      "role_id": roleId,
    };

    var requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
      redirect: 'follow'
    };

    fetch(`${baseUrl}/assign/role`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status) {

          let temp = [...users];
          temp[selectedIndex] = result.user;

          setUsers(temp);
          toast("Successfully Updated!");
        } else {
          console.log('message : ', result.message);
        }
      })
      .catch(error => console.log('error', error));


  }


  const init = () => {
    getRoles();
    getAdminUsers();
  }

  useEffect(() => {
    init();
  }, []);


  return (
    <div>
      <ConformationModal />


      <div>
        <div className="row">
          <div className="col-md-12">

            <div className='headingTopStyling' >
              <h3 className="title-5 m-b-35">Manage Users </h3>
              {/* <button type="button" class="btn btn-primary">Add Appointments</button> */}
            </div>
            <div className="table-data__tool">

            </div>
            <div className="table-responsive table-responsive-data2">
              <table className="table table-data2">
                <thead>
                  <tr>

                    <th>Name</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Created At</th>
                    <th>Updated At</th>
                    <th>Status</th>

                    <th className='text-center'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    users.map((item, index) => {
                      return (

                        <tr className="tr-shadow" key={item.id} >

                          <td>
                            <span className="status--process">{item.first_name} {item.last_name}</span>
                          </td>
                          <td>
                            <span className="status--process">{item.email}</span>
                          </td>
                          <td>
                            <span className="status--process">{item.roles.length > 0 && item.roles[0].name}</span>
                          </td>
                          <td>
                              <span className="status--process">{moment(item.created_at).format('DD MMM, YYYY')}</span>
                          </td>
                          <td>
                              <span className="status--process">{moment(item.updated_at).format('DD MMM, YYYY')}</span>
                          </td>
                          <td>
                            <span className="status--process">
                              <label class="switch switch-3d switch-success mr-3">
                                <input type="checkbox" class="switch-input" defaultChecked={item.status} onChange={(e) => toggleStatus(e, item.id, index)} />
                                <span class="switch-label"></span>
                                <span class="switch-handle"></span>
                              </label>
                            </span>
                          </td>


                          <td className='d-flex justify-content-center'>
                            <div className="table-data-feature">
                              <button className="item" data-toggle="tooltip" data-placement="top" title="Edit" onClick={() => { setShowEditModal(true); setSelectedIndex(index) }} >
                                <i className="zmdi zmdi-edit" />
                              </button>
                              {/* <button className="item" data-toggle="tooltip" data-placement="top" title="Delete">
                                <i className="zmdi zmdi-delete" data-toggle="modal" data-target="#smallmodal" />
                              </button> */}

                            </div>
                          </td>
                        </tr>

                      )

                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <ReactModal isOpen={showEditModal}>

          <div className="text-right">
            <h3 className='xInmodal' onClick={() => setShowEditModal(false)}>X</h3>
          </div>
          <h1>Edit Role</h1>

          <br />
          <br />

          <form onSubmit={updateData}>
            <div className="form-group">

              {/* <label >Name</label>
              <br />
              <input type="Text" className="form-control" placeholder="Doctor Name" /> */}

              <div class="col-12 col-md-12">
                <select name="select" id="select" class="form-control" onChange={(e) => setRoleId(e.target.value)} >
                  {
                    roles.map((item, index) => {
                      return (
                        <option value={item.id}>{item.name}</option>
                      )

                    })
                  }
                </select>
              </div>
            </div>
            <button type="submit" class="btn btn-primary">Edit</button>

          </form>

        </ReactModal>
        <ConformationModal />
        <ToastContainer position="bottom-right" />
      </div>

    </div>
  );
}

export default AdminUsers;