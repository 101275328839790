import React, { useContext } from 'react';
import { appContext } from '../context/ContextProvider';

function ConformationModal(props) {
    const { user } = useContext(appContext);

    return (
<>
		<div className="modal fade" id="smallmodal" tabIndex={-1} role="dialog" aria-labelledby="smallmodalLabel" aria-hidden="true">
  <div className="modal-dialog modal-sm" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="smallmodalLabel">Delete Item</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body">
        <p>
         Are You Sure You Want to Delete
        </p>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
        <button type="button" className="btn btn-primary">Confirm</button>
      </div>
    </div>
  </div>
</div>
</>


    )
}


export default ConformationModal;

