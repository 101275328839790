import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import ConformationModal from '../../components/ConformationModal';
import 'react-toastify/dist/ReactToastify.css';
import ReactModal from 'react-modal';
import { baseUrl } from '../../utils/Constants';
import _ from 'lodash';

function RolePermissions(props) {

    const [permissions, setPermissions] = useState([]);
    const [rolePermissions, setRolePermissions] = useState([]);
    const [selectedRoleIndex, setSelectedRoleIndex] = useState(null);
    const [roles, setRoles] = useState([]);
    const [showEditModal, setShowEditModal] = useState(false);

    const getPermissions = () => {
        toast("Fetching Permissions!");

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
        };

        fetch(`${baseUrl}/permissions`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status) {
                    setPermissions(result.data);
                } else {
                    alert('fetch error')
                }
            })
            .catch(error => {
                console.log('error', error);
            });
    }

    const updateRolePermissions = () => {
        toast("updating Roles & Permissions!");
        console.log('roles[selectedRoleIndex].permissions :: ', roles[selectedRoleIndex].permissions);

        let permissionNames = getPermissionsNames(roles[selectedRoleIndex].permissions);
        console.log('permissionNames :: ', permissionNames);

        var formdata = new FormData();
        formdata.append("role_id", roles[selectedRoleIndex].id);
        formdata.append("permissions", permissionNames);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${baseUrl}/update/role/permissions`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status) {
                    console.log('result :: ', result);

                    let temp = roles;
                    temp[selectedRoleIndex] = result.role;
                    setRoles(temp);

                } else {
                    console.log('message : ', result.message);
                }
            })
            .catch(error => console.log('error', error));

    }

    const getPermissionsNames = (arr) => {
        return _.map(arr, 'name');
    }

    const getRoles = () => {
        toast("Fetching roles!");

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
        };

        fetch(`${baseUrl}/roles?relations=permissions`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status) {
                    setRoles(result.data);

                    if (result.data.length > 0) {

                        setRolePermissions(result.data[0].permissions);
                        setSelectedRoleIndex(0);

                    } else { }

                } else {
                    alert('fetch error')
                }
            })
            .catch(error => {
                console.log('error', error);
            });
    }

    const roleChanged = (index) => {
        setSelectedRoleIndex(index);
        let temp = [...roles];
        let rolePermissions = temp[index].permissions;
        setRolePermissions(temp[index].permissions);
        // var filteredPermissions = [];

        // let allPermissions = [...permissions];

        // permissions.map((item, index) => {

        //     let temp = _.filter(rolePermissions, function (o) {
        //         if (o.name == item.name) {
        //             allPermissions[index].checked = true;
        //             return o;
        //         } else {
        //             allPermissions[index].checked = false;
        //         }

        //     })

        //     filteredPermissions.push(...temp)

        // })

        // console.log('filteredPermissions :: ', filteredPermissions);

    }

    const permissionAdded = (index) => {
        let temp = [...permissions];

        let tempRoles = [...roles]

        //push element into array if not exists
        let i = tempRoles[selectedRoleIndex].permissions.findIndex(_item => _item.name === temp[index].name);
        if (i < 0) {
            tempRoles[selectedRoleIndex].permissions.push(temp[index]);
        } else { }

        setRolePermissions(tempRoles[selectedRoleIndex].permissions);

        setRoles(tempRoles);

    }

    const permissionRemoved = (index) => {
        let tempRoles = [...roles];
        let tempPermissions = tempRoles[selectedRoleIndex].permissions;

        tempPermissions.splice(index, 1);
        tempRoles[selectedRoleIndex].permissions = tempPermissions;

        setRolePermissions(tempPermissions);
        setRoles(tempRoles);
    }

    const init = () => {
        getPermissions();
        getRoles();
    }

    useEffect(() => {
        init();
    }, []);

    return (
        <div>
            <div className="row">
                <div className="col-md-10">
                    <div class="card">
                        <div class="card-header">
                            Manage<strong> Roles & Permissions</strong>
                        </div>

                        <div class="card-body card-block">
                            <form action="" method="post" enctype="multipart/form-data" class="form-horizontal">
                                <div class="row form-group">
                                    <div class="col col-md-3">
                                        <label for="select" class=" form-control-label">Select Role</label>
                                    </div>
                                    <div class="col-12 col-md-9">
                                        <select name="select" id="select" class="form-control" onChange={(e) => roleChanged(e.target.value)} >
                                            {
                                                roles.map((item, index) => {
                                                    return (
                                                        <option value={index}>{item.name}</option>
                                                    )

                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <div class="col col-md-3">
                                        <label class=" form-control-label">Permissions</label>
                                    </div>
                                    <div class="col col-md-9">
                                        <div class="form-check" className='tilesview'  >
                                            {
                                                rolePermissions.map((item, index) => {
                                                    return (
                                                        <div class="checkbox" clasName="Card_Div" >
                                                            <label for="checkbox1" className="card_tags" class="form-check-label ">
                                                                {item.name}
                                                            </label>

                                                            <a href="#minus-square" onClick={() => permissionRemoved(index)}>
                                                                <i class="fa fa-minus-square"></i> </a>

                                                        </div>
                                                    )

                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <div class="col col-md-3">
                                        <label for="select" class=" form-control-label">Select Permissions For Role</label>
                                    </div>
                                    <div class="col-12 col-md-9">
                                        <select name="select" id="select" class="form-control" onChange={(e) => permissionAdded(e.target.value)} >
                                            {
                                                permissions.map((item, index) => {
                                                    return (
                                                        <option value={index}>{item.name}</option>
                                                    )

                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </div><div class="card-footer">
                            <button class="btn btn-primary btn-sm" onClick={updateRolePermissions} >
                                <i class="fa fa-dot-circle-o"></i> Update
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <ReactModal isOpen={showEditModal}>
                <div className="modal fade" id="RolesModal" tabIndex={-1} role="dialog" aria-labelledby="smallmodalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-sm" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="smallmodalLabel">Edit Roles</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <h6>Name</h6>
                                <div className="input-group">
                                    <input type="text" id="username2" name="username2" placeholder="Roles" className="form-control" />
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                                <button type="button" className="btn btn-primary">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ReactModal>


            <div className="modal fade" id="RolesModalAdd" tabIndex={-1} role="dialog" aria-labelledby="smallmodalLabel" aria-hidden="true">
                <div className="modal-dialog modal-sm" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="smallmodalLabel">Add Roles</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <h6>Name</h6>
                            <br />
                            <div className="input-group">
                                <input type="text" id="username2" name="username2" placeholder="Roles" className="form-control" />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-primary">Confirm</button>
                        </div>
                    </div>
                </div>
            </div>


            <ConformationModal />
            <ToastContainer position="bottom-right" />
        </div>
    );
}

export default RolePermissions;