import React, { useContext } from 'react';
import { Routes, Route } from "react-router-dom";

import Languages from "../pages/Languages";
import Countries from "../pages/Countries";
import Phrases from "../pages/Phrases";
import Packages from "../pages/Packages";
import CardsCategories from "../pages/CardsCategories";
import SubCardCategories from "../pages/SubCardCategories";
import Transactions from "../pages/Transactions";
import AppUsers from "../pages/AppUsers";
import AppointmentsPage from "../pages/AppointmentsPage";//not using in this app
import AdminUsers from "../pages/AdminUsers";
import Dashboard from "../pages/Dashboard";
import Roles from "../pages/acl/Roles";
import Permissions from "../pages/acl/Permissions";
import RolePermissions from "../pages/acl/RolePermissions";

import { appContext } from "../context/ContextProvider";

function Router(props) {
    const { user, setUser, setToken, setPermissions, permissions } = useContext(appContext);
    return (
        <Routes>
            {
                user.roles && user.roles.length > 0 &&
                <>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/languages" element={<Languages />} />
                    <Route path="/countries" element={<Countries />} />
                    <Route path="/cards_categories" element={<CardsCategories />} />
                    <Route path="/sub_card_categories" element={<SubCardCategories />} />
                    <Route path="/app_users" element={<AppUsers />} />
                    <Route path="/phrases" element={<Phrases />} />
                    <Route path="/transactions" element={<Transactions />} />
                    <Route path="/packages" element={<Packages />} />
                    {
                        user.roles && user.roles.length > 0 && user.roles[0].name == 'Super Admin' &&
                        <>
                            <Route path="/acl" element={<RolePermissions />} />
                            <Route path="/roles" element={<Roles />} />
                            <Route path="/roles-permissions" element={<RolePermissions />} />
                            <Route path="/permissions" element={<Permissions />} />
                            <Route path="/manage-admin-users" element={<AdminUsers />} />
                        </>
                    }

                    <Route path="*" element={<Dashboard />} />

                </>
            }
        </Routes>
    );
}

export default Router;