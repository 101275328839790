import logo from './logo.svg';
import './App.css';
import { BrowserRouter } from "react-router-dom";

import AppFlow from "../src/navigation/AppFlow";
import ContextProvider from './context/ContextProvider';

function App() {
  return (
    <BrowserRouter >
      <ContextProvider>
        <AppFlow />
      </ContextProvider>
    </BrowserRouter>
  );
}

export default App;
