import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import { appContext } from "../../context/ContextProvider";
import { baseUrl } from "../../utils/Constants";

function ForgotPassword(props) {
    const { user, setUser, setToken } = useContext(appContext);

    const notify = () => {
        toast("Link Sent Successfully!")
    };

    const [email, setEmail] = useState('');

    const onSubmitForm = (e) => {
        e.preventDefault();

        if (email != '') {

            var formdata = new FormData();
            formdata.append("email", email);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${baseUrl}/user/password/forgot`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status) {
                        toast("Link Sent Successfully!");
                    } else {
                        console.log('message : ', result.message);
                    }
                })
                .catch(error => console.log('error', error));

        } else {

            toast.error("Please Enter Valid Email!");
        }

    }

    return (
        <div class="login-wrap">
            <div class="login-content">
                <div class="login-logo">
                    <a href="#">
                        <h3>Forgot Password</h3>
                    </a>
                </div>
                <div class="login-form">
                    <form onSubmit={(e) => onSubmitForm(e)}>
                        <div class="form-group">
                            <label>Email Address</label>
                            <input class="au-input au-input--full" type="email" name="email" value={email} placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                        </div>

                        <button class="au-btn au-btn--block au-btn--green m-b-20">Send Link</button>
                    </form>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}

export default ForgotPassword;