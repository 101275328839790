import React, { useState, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { appContext } from "../../context/ContextProvider";
import { baseUrl } from "../../utils/Constants";

function EmailVerification(props) {
    const { user, setUser, setToken } = useContext(appContext);
    let navigate = useNavigate();
    let { state } = useLocation();

    const [email, setEmail] = useState(state.userEmail);
    const [code, setCode] = useState('');

    const onSubmitForm = (e) => {
        e.preventDefault();

        var formdata = new FormData();
        formdata.append("code", code);
        formdata.append("email", state.userEmail);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${baseUrl}/email/verify`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status) {
                    navigate("/login");
                } else {
                    alert('message : ', result.message);
                }
            })
            .catch(error => console.log('error', error));

    }

    return (
        <div class="login-wrap">
            <div class="login-content">
                <div class="login-logo">
                    <a href="#">
                        <img src="images/icon/logo.png" alt="CoolAdmin" />
                        <h3>Code Verification</h3>
                    </a>
                </div>
                <div class="login-form">
                    <form onSubmit={(e) => onSubmitForm(e)}>
                        <div class="form-group">
                            <label>Email Address</label>
                            <input class="au-input au-input--full" type="email" name="email" value={email} placeholder="Email" disabled />
                        </div>
                        <div class="form-group">
                            <label>Code</label>
                            <input class="au-input au-input--full" type="number" name="code" value={code} placeholder="XXXX" onChange={(e) => setCode(e.target.value)} />
                        </div>
                        <button class="au-btn au-btn--block au-btn--green m-b-20">Verify</button>
                    </form>
                    <div class="register-link">
                        <p>
                            Don't you have account?
                            <Link to={'/register'}>Sign Up Here</Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EmailVerification;