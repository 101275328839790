import React, { useContext, useState, useEffect } from 'react';
import { appContext } from '../context/ContextProvider';
import ConformationModal from '../components/ConformationModal'
import 'react-toastify/dist/ReactToastify.css';
import ReactModal from 'react-modal';
import { baseUrl } from '../utils/Constants';
import { ToastContainer, toast } from 'react-toastify';
import _ from "lodash";
import { Formik, Field } from 'formik';

function Phrases(props) {

    const { user, permissions } = useContext(appContext);
    const [packages, setPackages] = useState([]);
    const [dependencies, setDependencies] = useState({});
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [paginationData, setPaginationData] = useState({
        current_page: 0,
        first_page_url: "http://app_url/api/admin_doctors?page=1",
        from: 0,
        last_page: 0,
        last_page_url: "http://app_url/api/admin_doctors?page=2",
        next_page_url: null,
        path: "http://app_url/api/admin_doctors",
        per_page: parseInt("10"),
        prev_page_url: "http://app_url/api/admin_doctors?page=1",
        to: 0,
        total: 0
    });

    const getPagePackages = (page) => {
        toast("Fetching packages!");

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
        };

        fetch(`${baseUrl}/packages?paginated=true&limit=10&page=${page}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status) {
                    setPackages(result.data.data);
                    setPaginationData({
                        current_page: result.data.current_page,
                        first_page_url: result.data.first_page_url,
                        from: result.data.from,
                        last_page: result.data.last_page,
                        last_page_url: result.data.last_page_url,
                        next_page_url: result.data.next_page_url,
                        path: result.data.path,
                        per_page: result.data.per_page,
                        prev_page_url: result.data.prev_page_url,
                        to: result.data.to,
                        total: result.data.total
                    })
                } else {
                    alert('fetch error')
                }
            })
            .catch(error => {
                console.log('error', error);
            });
    }

    const getPackages = () => {
        toast("Fetching packages!");

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
        };

        fetch(`${baseUrl}/packages?paginated=true&limit=10`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status) {
                    setPackages(result.data.data);
                    setPaginationData({
                        current_page: result.data.current_page,
                        first_page_url: result.data.first_page_url,
                        from: result.data.from,
                        last_page: result.data.last_page,
                        last_page_url: result.data.last_page_url,
                        next_page_url: result.data.next_page_url,
                        path: result.data.path,
                        per_page: result.data.per_page,
                        prev_page_url: result.data.prev_page_url,
                        to: result.data.to,
                        total: result.data.total
                    })
                } else {
                    alert('fetch error')
                }
            })
            .catch(error => {
                console.log('error', error);
            });
    }

    const getDependencies = () => {
        //call dependencies methods
    }

    const init = () => {
        getPackages();
        getDependencies();
    }

    useEffect(() => {
        init();
    }, []);

    const getBool = (val) => {
        return !!JSON.parse(String(val).toLowerCase());
    }

    const updateData = (values) => {

        setShowEditModal(false);

        var data = {
            "name": values.name,
            "price": values.price,
            "price_currency": values.price_currency,
            "validity_months": values.validity_months,
            "description": values.description,
        };

        var requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
            redirect: 'follow'
        };

        fetch(`${baseUrl}/packages/${packages[selectedIndex].id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result) {

                    // let temp = [...packages];
                    // temp[selectedIndex] = result;

                    // setPackages(temp);
                    getPagePackages(paginationData.current_page);
                    toast("Successfully Updated!");
                } else {
                    console.log('message : ', result.message);
                }
            })
            .catch(error => console.log('error', error));


    }

    const addData = (values) => {

        setShowAddModal(false);
        var data = {
            "name": values.name,
            "price": values.price,
            "price_currency": values.price_currency,
            "validity_months": values.validity_months,
            "description": values.description,
        };

        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
            redirect: 'follow'
        };

        fetch(`${baseUrl}/packages`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result) {

                    // let temp = [...shops];
                    // temp.unshift(result.items);

                    // setPackages(temp);
                    getPackages();
                    toast("Successfully Added!");
                } else {
                    console.log('message : ', result.message);
                }
            })
            .catch(error => console.log('error', error));


    }

    const goToPreviousPage = () => {
        paginationData.prev_page_url && getPagePackages(paginationData.current_page - 1);
    }

    const goToNextPage = () => {
        paginationData.next_page_url && getPagePackages(paginationData.current_page + 1);
    }

    return (
        <div>
            <ConformationModal />
            <div>
                <div className="row">
                    <div className="col-md-12">

                        <div className='headingTopStyling' >
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>

                                <h3 className="title-5 m-b-35">Packages</h3>

                            </div>
                            <div>

                                <h3>{paginationData.total} ({paginationData.from} - {paginationData.to})</h3>


                                <a className='btnstyling' href="#" onClick={goToPreviousPage} >&laquo;</a>
                                <a className='btnstyling' href="#" onClick={goToNextPage} >&raquo;</a>


                            </div>

                        </div>
                        <button type="button" class="btn btn-success" onClick={() => setShowAddModal(true)} >Add Package</button>

                        <div className="table-data__tool">

                        </div>
                        <div className="table-responsive table-responsive-data2">
                            <table className="table table-data2">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Price</th>
                                        <th>Price Currency</th>
                                        <th>Validity Months</th>
                                        <th>Description</th>
                                        {
                                            permissions.includes("packages-edit") &&
                                            <>
                                                <th className='text-center'>Actions</th>
                                            </>
                                        }


                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        packages.map((item, index) => {
                                            return (

                                                <tr className="tr-shadow" key={item.id} >
                                                    <td>
                                                        <span className="status--process">{item.name}</span>
                                                    </td>
                                                    <td>
                                                        <span className="status--process">{item.price}</span>
                                                    </td>
                                                    <td>
                                                        <span className="status--process">{item.price_currency}</span>
                                                    </td>
                                                    <td>
                                                        <span className="status--process">{item.validity_months}</span>
                                                    </td>
                                                    <td>
                                                        <span className="status--process">{item.description}</span>
                                                    </td>
                                                    <td className='d-flex justify-content-center'>
                                                        <div className="table-data-feature">
                                                            {
                                                                permissions.includes("packages-edit") &&
                                                                <>
                                                                    <button className="item" data-toggle="tooltip" data-placement="top" title="Edit" onClick={() => { setShowEditModal(true); setSelectedIndex(index) }} >
                                                                        <i className="zmdi zmdi-edit" />
                                                                    </button>
                                                                </>
                                                            }
                                                            {/* <button className="item" data-toggle="tooltip" data-placement="top" title="Delete">
                                  <i className="zmdi zmdi-delete" data-toggle="modal" data-target="#smallmodal" />
                                </button> */}

                                                        </div>
                                                    </td>
                                                </tr>


                                            )

                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <ReactModal isOpen={showEditModal}>

                    <div className="text-right">
                        <h3 className='xInmodal' onClick={() => setShowEditModal(false)}>X</h3>
                    </div>
                    <h1 className='topmodalH'>Edit Package</h1>

                    <br />
                    <br />
                    <Formik
                        initialValues={{
                            name: packages.length > 0 && packages[selectedIndex].name,
                            price: packages.length > 0 && packages[selectedIndex].price,
                            price_currency: packages.length > 0 && packages[selectedIndex].price_currency,
                            validity_months: packages.length > 0 && packages[selectedIndex].validity_months,
                            description: packages.length > 0 && packages[selectedIndex].description,
                        }}
                        validate={values => {
                            const errors = {};
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            updateData(values);
                            // setTimeout(() => {
                            //     alert(JSON.stringify(values, null, 2));
                            //     setSubmitting(false);
                            // }, 400);
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue
                            /* and other goodies */
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <div className='row'>
                                        <div className='col'>
                                            <label className='labelName' >Name</label>
                                            <br />
                                            <input type="Text" className="form-control" placeholder="Enter Name" name='name'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.name}
                                            />
                                            <label className='labelName' >Price</label>
                                            <br />
                                            <input type="Text" className="form-control" placeholder="Enter Price" name='price'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.price}
                                            />
                                            <label className='labelName' >Currency</label>
                                            <br />
                                            <input type="Text" className="form-control" placeholder="Enter Currency" name='price_currency'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.price_currency}
                                            />
                                        </div>
                                        <div className='col'>
                                            <label className='labelName' >Validity Months</label>
                                            <br />
                                            <input type="Text" className="form-control" placeholder="Enter validity Number" name='validity_months'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.validity_months}
                                            />
                                            <label className='labelName' >Description</label>
                                            <br />
                                            <input type="Text" className="form-control" placeholder="Enter Description" name='description'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.description}
                                            />
                                              <br />
                                              <br />
                                            <button type="submit" className="btn btn-success btn-lg">
                                                Edit
                                            </button>
                                        </div>

                                    </div>
                                </div>


                            </form>
                        )}
                    </Formik>

                    {/* 
                    <form onSubmit={updateData} >
                        <div className="form-group">
                            <label >Name</label>
                            <br />
                            <input type="Text" className="form-control" placeholder="Enter Name" value={packages.length > 0 && packages[selectedIndex].name} onChange={(e) => { let temp = [...packages]; temp[selectedIndex].name = e.target.value; setPackages(temp) }} />
                        </div>
                        <button type="submit" class="btn btn-primary">Edit</button>

                    </form> */}

                </ReactModal>

                <ReactModal isOpen={showAddModal}>

                    <div className="text-right">
                        <h3 className='xInmodal' onClick={() => setShowAddModal(false)}>X</h3>
                    </div>
                    <h1 className='topmodalH'>Add Package</h1>

                    <br />
                    <br />
                    <Formik
                        initialValues={{
                            name: '',
                            price: '',
                            price_currency: '',
                            validity_months: '',
                            description: '',
                        }}
                        validate={values => {
                            const errors = {};
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            addData(values);
                            // setTimeout(() => {
                            //     alert(JSON.stringify(values, null, 2));
                            //     setSubmitting(false);
                            // }, 400);
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue
                            /* and other goodies */
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">

                                    <div className='row'>

                                        <div className='col'>
                                            <label className='labelName' >Name</label>
                                            <br />
                                            <input type="Text" className="form-control" placeholder="Enter Name" name='name'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.name}
                                            />
                                            <label className='labelName' >Price</label>
                                            <br />
                                            <input type="Text" className="form-control" placeholder="Enter Price" name='price'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.price}
                                            />
                                            <label className='labelName' >Currency</label>
                                            <br />
                                            <input type="Text" className="form-control" placeholder="Enter Currency" name='price_currency'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.price_currency}
                                            />
                                        </div>
                                        <div className='col'>
                                            <label className='labelName' >Validity Months</label>
                                            <br />
                                            <input type="Text" className="form-control" placeholder="Enter validity Number" name='validity_months'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.validity_months}
                                            />
                                            <label className='labelName' >Description</label>
                                            <br />
                                            <input type="Text" className="form-control" placeholder="Enter Description" name='description'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.description}
                                            />
                                            <br />
                                            <br />

                                            <button type="submit" className="btn btn-primary btn-lg">
                                                Add
                                            </button>
                                        </div>

                                    </div>

                                </div>


                            </form>
                        )}
                    </Formik>
                </ReactModal>
                <ConformationModal />
                <ToastContainer position="bottom-right" />
            </div>

        </div>
    );
}

export default Phrases;