import React, { useContext, useState, useEffect } from 'react';
import { appContext } from '../context/ContextProvider';
import ConformationModal from '../components/ConformationModal'
import 'react-toastify/dist/ReactToastify.css';
import ReactModal from 'react-modal';
import { baseUrl } from '../utils/Constants';
import { ToastContainer, toast } from 'react-toastify';
import _ from "lodash";
import { Formik, Field } from 'formik';

function Transactions(props) {

    const { user, permissions } = useContext(appContext);
    const [transactions, setTransactions] = useState([]);
    const [dependencies, setDependencies] = useState({});
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [paginationData, setPaginationData] = useState({
        current_page: 0,
        first_page_url: "http://app_url/api/admin_doctors?page=1",
        from: 0,
        last_page: 0,
        last_page_url: "http://app_url/api/admin_doctors?page=2",
        next_page_url: null,
        path: "http://app_url/api/admin_doctors",
        per_page: parseInt("10"),
        prev_page_url: "http://app_url/api/admin_doctors?page=1",
        to: 0,
        total: 0
    });

    const getPageTransactions = (page) => {
        toast("Fetching transactions!");

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
        };

        fetch(`${baseUrl}/transactions?relations=user,package&paginated=true&limit=10&page=${page}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status) {
                    setTransactions(result.data.data);
                    setPaginationData({
                        current_page: result.data.current_page,
                        first_page_url: result.data.first_page_url,
                        from: result.data.from,
                        last_page: result.data.last_page,
                        last_page_url: result.data.last_page_url,
                        next_page_url: result.data.next_page_url,
                        path: result.data.path,
                        per_page: result.data.per_page,
                        prev_page_url: result.data.prev_page_url,
                        to: result.data.to,
                        total: result.data.total
                    })
                } else {
                    alert('fetch error')
                }
            })
            .catch(error => {
                console.log('error', error);
            });
    }

    const getTransactions = () => {
        toast("Fetching transactions!");

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
        };

        fetch(`${baseUrl}/transactions?relations=user,package&paginated=true&limit=10`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status) {
                    setTransactions(result.data.data);
                    setPaginationData({
                        current_page: result.data.current_page,
                        first_page_url: result.data.first_page_url,
                        from: result.data.from,
                        last_page: result.data.last_page,
                        last_page_url: result.data.last_page_url,
                        next_page_url: result.data.next_page_url,
                        path: result.data.path,
                        per_page: result.data.per_page,
                        prev_page_url: result.data.prev_page_url,
                        to: result.data.to,
                        total: result.data.total
                    })
                } else {
                    alert('fetch error')
                }
            })
            .catch(error => {
                console.log('error', error);
            });
    }

    const getLanguages = () => {
        toast("Fetching languages!");

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
        };

        fetch(`${baseUrl}/languages`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status) {
                    let temp = _.cloneDeep(dependencies);
                    temp.languages = result.data
                    setDependencies(temp);
                } else {
                    alert('fetch error')
                }
            })
            .catch(error => {
                console.log('error', error);
            });
    }

    const getDependencies = () => {
        // getLanguages();
    }

    const init = () => {
        getTransactions();
        getDependencies();
    }

    useEffect(() => {
        init();
    }, []);

    const getBool = (val) => {
        return !!JSON.parse(String(val).toLowerCase());
    }

    const updateData = (values) => {

        setShowEditModal(false);

        var data = {
            "name": values.name,
            "language_id": values.language_id,
            "is_paid": getBool(values.is_paid),
        };

        var requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
            redirect: 'follow'
        };

        fetch(`${baseUrl}/transactions/${transactions[selectedIndex].id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result) {

                    // let temp = [...transactions];
                    // temp[selectedIndex] = result;

                    // setTransactions(temp);
                    getPageTransactions(paginationData.current_page);
                    toast("Successfully Updated!");
                } else {
                    console.log('message : ', result.message);
                }
            })
            .catch(error => console.log('error', error));


    }

    const addData = (values) => {

        setShowAddModal(false);
        var data = {
            "name": values.name,
            "language_id": values.language_id,
            "is_paid": getBool(values.is_paid),
            "is_default": true,
        };

        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
            redirect: 'follow'
        };

        fetch(`${baseUrl}/transactions`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result) {

                    // let temp = [...shops];
                    // temp.unshift(result.items);

                    // setTransactions(temp);
                    getTransactions();
                    toast("Successfully Added!");
                } else {
                    console.log('message : ', result.message);
                }
            })
            .catch(error => console.log('error', error));


    }

    const goToPreviousPage = () => {
        paginationData.prev_page_url && getPageTransactions(paginationData.current_page - 1);
    }

    const goToNextPage = () => {
        paginationData.next_page_url && getPageTransactions(paginationData.current_page + 1);
    }

    return (
        <div>
            <ConformationModal />
            <div>
                <div className="row">
                    <div className="col-md-12">

                        <div className='headingTopStyling' >
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>

                                <h3 className="title-5 m-b-35">Transactions</h3>
                               
                            </div>
                            <div>

                                <h3>{paginationData.total} ({paginationData.from} - {paginationData.to})</h3>


                                <a className='btnstyling' href="#" onClick={goToPreviousPage} >&laquo;</a>
                                <a className='btnstyling' href="#" onClick={goToNextPage} >&raquo;</a>


                            </div>

                        </div>

                        <div className="table-data__tool">

                        </div>
                        <div className="table-responsive table-responsive-data2">
                            <table className="table table-data2">
                                <thead>
                                    <tr>
                                        <th>User Name</th>
                                        <th>User Email</th>
                                        <th>Package</th>
                                        <th>Payment Status</th>
                                        <th>Amount</th>
                                        <th>Transaction Id</th>
                                        {/* {
                                            permissions.includes("transactions-edit") &&
                                            <>
                                                <th className='text-center'>Actions</th>
                                            </>
                                        } */}

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        transactions.map((item, index) => {
                                            return (

                                                <tr className="tr-shadow" key={item.id} >
                                                    <td>
                                                        <span className="status--process">{item.user && item.user != null && item.user.first_name + item.user.last_name}</span>
                                                    </td>
                                                    <td>
                                                        <span className="status--process">{item.user && item.user != null && item.user.email}</span>
                                                    </td>
                                                    <td>
                                                        <span className="status--process">{item.package && item.package != null && item.package.name}</span>
                                                    </td>
                                                    <td>
                                                        <span className="status--process">{item.payment_status}</span>
                                                    </td>
                                                    <td>
                                                        <span className="status--process">${item.package && item.package != null && item.package.price}</span>
                                                    </td>
                                                    <td>
                                                        <span className="status--process">{item.txn_id}</span>
                                                    </td>
                                                </tr>


                                            )

                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <ReactModal isOpen={showEditModal}>

                    <div className="text-right">
                        <h3 className='xInmodal' onClick={() => setShowEditModal(false)}>X</h3>
                    </div>
                    <h1 className='topmodalH'>Edit Phrase</h1>

                    <br />
                    <br />
                    <Formik
                        initialValues={{ name: transactions.length > 0 && transactions[selectedIndex].name, 
                            language_id: transactions.length > 0 && transactions[selectedIndex].language && transactions[selectedIndex].language.id,
                            is_paid: transactions.length > 0 && transactions[selectedIndex].is_paid
                        }}
                        validate={values => {
                            const errors = {};
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            updateData(values);
                            // setTimeout(() => {
                            //     alert(JSON.stringify(values, null, 2));
                            //     setSubmitting(false);
                            // }, 400);
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue
                            /* and other goodies */
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label className='labelName' >Name</label>
                                    <br />
                                    <input type="Text" className="form-control" placeholder="Enter Name" name='name'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.name}
                                    />
                                    <label className='labelName' >Language</label>
                                    <br />
                                    <Field as="select" name="language_id" class='form-control' >
                                    {
                                            dependencies.languages && dependencies.languages.map((item, index) => {
                                                return (
                                                    <option value={item.id}>{item.name}</option>
                                                )

                                            })
                                        }
                                    </Field>
                                    <label className='labelName' >Paid</label>
                                    <br />
                                    <span className="status--process">
                                    <label class="switch switch-3d switch-success mr-3">
                                        <input type="checkbox" class="switch-input" defaultChecked={values.is_paid} onChange={(e) => setFieldValue('is_paid', !values.is_paid)} />
                                        <span class="switch-label"></span>
                                        <span class="switch-handle"></span>
                                    </label>
                                    </span>
                                    
                                </div>
                                <button type="submit" className="btn btn-primary">
                                    Edit
                                </button>
                            </form>
                        )}
                    </Formik>

{/* 
                    <form onSubmit={updateData} >
                        <div className="form-group">
                            <label >Name</label>
                            <br />
                            <input type="Text" className="form-control" placeholder="Enter Name" value={transactions.length > 0 && transactions[selectedIndex].name} onChange={(e) => { let temp = [...transactions]; temp[selectedIndex].name = e.target.value; setTransactions(temp) }} />
                        </div>
                        <button type="submit" class="btn btn-primary">Edit</button>

                    </form> */}

                </ReactModal>

                <ReactModal isOpen={showAddModal}>

                    <div className="text-right">
                        <h3 className='xInmodal' onClick={() => setShowAddModal(false)}>X</h3>
                    </div>
                    <h1 className='topmodalH'>Add Phrase</h1>

                    <br />
                    <br />
                    <Formik
                        initialValues={{ name: '', language_id: 1, is_paid: false }}
                        validate={values => {
                            const errors = {};
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            addData(values);
                            // setTimeout(() => {
                            //     alert(JSON.stringify(values, null, 2));
                            //     setSubmitting(false);
                            // }, 400);
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue
                            /* and other goodies */
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label className='labelName' >Name</label>
                                    <br />
                                    <input type="Text" className="form-control" placeholder="Enter Name" name='name'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.name}
                                    />
                                    <label className='labelName' >Language</label>
                                    <br />
                                    <Field as="select" name="language_id" class='form-control' >
                                    {
                                            dependencies.languages && dependencies.languages.map((item, index) => {
                                                return (
                                                    <option value={item.id}>{item.name}</option>
                                                )

                                            })
                                        }
                                    </Field>
                                    <label className='labelName' >Paid</label>
                                    <br />
                                    <span className="status--process">
                                    <label class="switch switch-3d switch-success mr-3">
                                        <input type="checkbox" class="switch-input" defaultChecked={values.is_paid} onChange={(e) => setFieldValue('is_paid', !values.is_paid)} />
                                        <span class="switch-label"></span>
                                        <span class="switch-handle"></span>
                                    </label>
                                    </span>
                                </div>
                                <button type="submit" className="btn btn-success btn-lg">
                                    Add
                                </button>
                            </form>
                        )}
                    </Formik>
                </ReactModal>
                <ConformationModal />
                <ToastContainer position="bottom-right" />
            </div>

        </div>
    );
}

export default Transactions;