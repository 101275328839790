import React from 'react';
import AuthRouter from '../router/AuthRouter';

function AuthFlow(props) {
    return (
        <div class="page-wrapper">
            <div class="page-content--bge5">
                <div class="container">
                    <AuthRouter />
                </div>
            </div>

        </div>
    );
}

export default AuthFlow;