import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { baseUrl } from '../utils/Constants';
import { ToastContainer, toast } from 'react-toastify';

function Dashboard(props) {

  const [totalUsers, setTotalUsers] = useState(0);
  const [paidUsers, setPaidUsers] = useState(0);
  const [unpaidUsers, setUnpaidUsers] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);

  const getDashboardStats = () => {
    toast("Fetching Stats!");

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
        };

        fetch(`${baseUrl}/get/dashboard/stats`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status) {
                    setTotalUsers(result.data.users_count);
                    setPaidUsers(result.data.paid_users);
                    setUnpaidUsers(result.data.unpaid_users);
                    setTotalRevenue(result.data.total_revenue);
                } else {
                    alert('fetch error')
                }
            })
            .catch(error => {
                console.log('error', error);
            });
  }

  const init = () => {
    getDashboardStats();
}

useEffect(() => {
    init();
}, []);

    return (
       <div>
  <section className="statistic statistic2">
    <div className="container">
      <div className="row">
        <div className="col-md-6 col-lg-3">
          <div className="statistic__item statistic__item--blue">
            <h2 className="number">{totalUsers}</h2>
            <span className="desc">Total Users</span>
            <div className="icon">
              <i className="zmdi zmdi-account-o" />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          <div className="statistic__item statistic__item--green">
            <h2 className="number">{paidUsers}</h2>
            <span className="desc">Total Paid Users</span>
            <div className="icon">
              <i className="zmdi zmdi-shopping-cart" />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          <div className="statistic__item statistic__item--red">
            <h2 className="number">{unpaidUsers}</h2>
            <span className="desc">Total Free Users</span>
            <div className="icon">
              <i className="zmdi zmdi-calendar-note" />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          <div className="statistic__item statistic__item--orange">
            <h2 className="number">${totalRevenue}</h2>
            <span className="desc">Total Revenue in Current</span>
            <div className="icon">
              <i className="zmdi zmdi-money" />
            </div>
          </div>
        </div>
  
      </div>
    </div>
  </section>
  <ToastContainer position="bottom-right" />
</div>

    );
}

export default Dashboard;