import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import ConformationModal from '../../components/ConformationModal';
import 'react-toastify/dist/ReactToastify.css';
import ReactModal from 'react-modal';
import { baseUrl } from '../../utils/Constants';

function Permissions(props) {

    const [permissions, setPermissions] = useState([]);
    const [showEditModal, setShowEditModal] = useState(false);

    const getPermissions = () => {
        toast("Fetching Permissions!");

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
        };

        fetch(`${baseUrl}/permissions`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status) {
                    setPermissions(result.data);
                } else {
                    alert('fetch error')
                }
            })
            .catch(error => {
                console.log('error', error);
            });
    }

    const init = () => {
        getPermissions();
    }

    useEffect(() => {
        init();
    }, []);

    return (
        <div>
            <div className="row">
                <div className="col-md-6">

                    <h3 className="title-5 m-b-35">Permissions </h3>
                    <div className="table-data__tool">

                    </div>
                    <div className="table-responsive table-responsive-data2">
                        <table className="table table-data2">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    {/* <th className='text-center'>Actions</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    permissions.map((item, index) => {
                                        return (
                                            <tr key={item.id} className="tr-shadow">
                                                <td>
                                                    <span className="status--process">{item.name}</span>
                                                </td>
                                                {/* <td className='d-flex justify-content-center'>
                                                    <div className="table-data-feature">
                                                        <button className="item" data-toggle="tooltip" data-placement="top" title="Edit" onClick={() => setShowEditModal(true)} >
                                                            <i className="zmdi zmdi-edit" />
                                                        </button>
                                                        <button className="item" data-toggle="tooltip" data-placement="top" title="Delete">
                                                            <i className="zmdi zmdi-delete" data-toggle="modal" data-target="#smallmodal" />
                                                        </button>
                                                        <button className="item" data-toggle="tooltip" data-placement="top" title="Add">
                                                            <i className="zmdi zmdi-plus" data-toggle="modal" data-target="#RolesModalAdd" />
                                                        </button>
                                                    </div>
                                                </td> */}
                                            </tr>
                                        )

                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <ReactModal isOpen={showEditModal}>
                <div className="modal fade" id="RolesModal" tabIndex={-1} role="dialog" aria-labelledby="smallmodalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-sm" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="smallmodalLabel">Edit Roles</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <h6>Name</h6>
                                <div className="input-group">
                                    <input type="text" id="username2" name="username2" placeholder="Roles" className="form-control" />
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                                <button type="button" className="btn btn-primary">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ReactModal>


            <div className="modal fade" id="RolesModalAdd" tabIndex={-1} role="dialog" aria-labelledby="smallmodalLabel" aria-hidden="true">
                <div className="modal-dialog modal-sm" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="smallmodalLabel">Add Roles</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <h6>Name</h6>
                            <br />
                            <div className="input-group">
                                <input type="text" id="username2" name="username2" placeholder="Roles" className="form-control" />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-primary">Confirm</button>
                        </div>
                    </div>
                </div>
            </div>


            <ConformationModal />
            <ToastContainer position="bottom-right" />
        </div>
    );
}

export default Permissions;