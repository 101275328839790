import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import MainFlow from './MainFlow';
import AuthFlow from './AuthFlow';
import { appContext } from '../context/ContextProvider';

function AppFlow(props) {
    const { user, setUser, setToken, setPermissions } = useContext(appContext);

    const checkSession = () => {
        let loggedInUser = localStorage.getItem('user');
        let userPermissions = localStorage.getItem('permissions');

        if (loggedInUser) {
            setUser(JSON.parse(loggedInUser));
            setPermissions(JSON.parse(userPermissions));
        } else {

        }
    }

    useEffect(() => {
        checkSession();
    }, []);

    return (
        <>
            {
                user ?
                    <MainFlow />
                    :
                    <AuthFlow />
            }
            {/* <MainFlow /> */}
        </>
    );
}

export default AppFlow;