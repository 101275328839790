import React, { useContext, useState, useEffect } from 'react';
import { appContext } from '../context/ContextProvider';
import ConformationModal from '../components/ConformationModal'
import 'react-toastify/dist/ReactToastify.css';
import ReactModal from 'react-modal';
import { baseUrl } from '../utils/Constants';
import { ToastContainer, toast } from 'react-toastify';
import { Formik } from 'formik';

export default function Countries(props){

    const { user, permissions } = useContext(appContext);
    const [shops, setShops] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [paginationData, setPaginationData] = useState({
        current_page: 0,
        first_page_url: "http://app_url/api/admin_doctors?page=1",
        from: 0,
        last_page: 0,
        last_page_url: "http://app_url/api/admin_doctors?page=2",
        next_page_url: null,
        path: "http://app_url/api/admin_doctors",
        per_page: parseInt("10"),
        prev_page_url: "http://app_url/api/admin_doctors?page=1",
        to: 0,
        total: 0
    });

    const getPageShops = (page) => {
        toast("Fetching shops!");

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
        };

        fetch(`${baseUrl}/shops?paginated=true&limit=10&page=${page}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status) {
                    setShops(result.data.data);
                    setPaginationData({
                        current_page: result.data.current_page,
                        first_page_url: result.data.first_page_url,
                        from: result.data.from,
                        last_page: result.data.last_page,
                        last_page_url: result.data.last_page_url,
                        next_page_url: result.data.next_page_url,
                        path: result.data.path,
                        per_page: result.data.per_page,
                        prev_page_url: result.data.prev_page_url,
                        to: result.data.to,
                        total: result.data.total
                    })
                } else {
                    alert('fetch error')
                }
            })
            .catch(error => {
                console.log('error', error);
            });
    }

    const getShops = () => {
        toast("Fetching shops!");

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
        };

        fetch(`${baseUrl}/shops?paginated=true&limit=10`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status) {
                    setShops(result.data.data);
                    setPaginationData({
                        current_page: result.data.current_page,
                        first_page_url: result.data.first_page_url,
                        from: result.data.from,
                        last_page: result.data.last_page,
                        last_page_url: result.data.last_page_url,
                        next_page_url: result.data.next_page_url,
                        path: result.data.path,
                        per_page: result.data.per_page,
                        prev_page_url: result.data.prev_page_url,
                        to: result.data.to,
                        total: result.data.total
                    })
                } else {
                    alert('fetch error')
                }
            })
            .catch(error => {
                console.log('error', error);
            });
    }

    const init = () => {
        getShops();
    }

    useEffect(() => {
        init();
    }, []);

    const updateData = (e) => {
        e.preventDefault();

        setShowEditModal(false);

        var data = {
            "name": shops[selectedIndex].name,
            "address": shops[selectedIndex].address,
            "latitude": shops[selectedIndex].latitude,
            "longitude": shops[selectedIndex].longitude,
        };

        var requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
            redirect: 'follow'
        };

        fetch(`${baseUrl}/shops/${shops[selectedIndex].id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result) {

                    // let temp = [...shops];
                    // temp[selectedIndex] = result;

                    // setShops(temp);
                    toast("Successfully Updated!");
                } else {
                    console.log('message : ', result.message);
                }
            })
            .catch(error => console.log('error', error));


    }

    const addData = (values) => {

        setShowAddModal(false);
        var data = {
            "name": values.name,
            "address": values.address,
            "latitude": values.latitude,
            "longitude": values.longitude,
        };

        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
            redirect: 'follow'
        };

        fetch(`${baseUrl}/shops`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result) {

                    // let temp = [...shops];
                    // temp.unshift(result.items);

                    // setShops(temp);
                    getShops();
                    toast("Successfully Added!");
                } else {
                    console.log('message : ', result.message);
                }
            })
            .catch(error => console.log('error', error));


    }

    const goToPreviousPage = () => {
        paginationData.prev_page_url && getPageShops(paginationData.current_page - 1);
    }

    const goToNextPage = () => {
        paginationData.next_page_url && getPageShops(paginationData.current_page + 1);
    }

    return (
        <div>
            <ConformationModal />
            <div>
                <div className="row">
                    <div className="col-md-12">

                        <div className='headingTopStyling' >
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>

                                <h3 className="title-5 m-b-35">Shops</h3>
                                <button type="button" class="btn btn-primary" onClick={() => setShowAddModal(true)} >Add Shop</button>
                            </div>
                            <div>

                                <h3>{paginationData.total} ({paginationData.from} - {paginationData.to})</h3>


                                <a className='btnstyling' href="#" onClick={goToPreviousPage} >&laquo;</a>
                                <a className='btnstyling' href="#" onClick={goToNextPage} >&raquo;</a>


                            </div>

                        </div>

                        <div className="table-data__tool">

                        </div>
                        <div className="table-responsive table-responsive-data2">
                            <table className="table table-data2">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Address</th>
                                        <th>Latitude</th>
                                        <th>Longitude</th>
                                        {
                                            permissions.includes("shops-edit") &&
                                            <>
                                                <th className='text-center'>Actions</th>
                                            </>
                                        }


                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        shops.map((item, index) => {
                                            return (

                                                <tr className="tr-shadow" key={item.id} >
                                                    <td>
                                                        <span className="status--process">{item.name}</span>
                                                    </td>
                                                    <td>
                                                        <span className="status--process">{item.address}</span>
                                                    </td>
                                                    <td>
                                                        <span className="status--process">{item.latitude}</span>
                                                    </td>
                                                    <td>
                                                        <span className="status--process">{item.longitude}</span>
                                                    </td>

                                                    <td className='d-flex justify-content-center'>
                                                        <div className="table-data-feature">
                                                            {
                                                                permissions.includes("shops-edit") &&
                                                                <>
                                                                    <button className="item" data-toggle="tooltip" data-placement="top" title="Edit" onClick={() => { setShowEditModal(true); setSelectedIndex(index) }} >
                                                                        <i className="zmdi zmdi-edit" />
                                                                    </button>
                                                                </>
                                                            }
                                                            {/* <button className="item" data-toggle="tooltip" data-placement="top" title="Delete">
                                  <i className="zmdi zmdi-delete" data-toggle="modal" data-target="#smallmodal" />
                                </button> */}

                                                        </div>
                                                    </td>
                                                </tr>


                                            )

                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <ReactModal isOpen={showEditModal}>

                    <div className="text-right">
                        <h3 className='xInmodal' onClick={() => setShowEditModal(false)}>X</h3>
                    </div>
                    <h1>Edit Shop</h1>

                    <br />
                    <br />


                    <form onSubmit={updateData} >
                        <div className="form-group">
                            <label >Name</label>
                            <br />
                            <input type="Text" className="form-control" placeholder="Enter Name" value={shops.length > 0 && shops[selectedIndex].name} onChange={(e) => { let temp = [...shops]; temp[selectedIndex].name = e.target.value; setShops(temp) }} />

                            <label >Address</label>
                            <br />
                            <input type="Text" className="form-control" placeholder="Enter Address" value={shops.length > 0 && shops[selectedIndex].address} onChange={(e) => { let temp = [...shops]; temp[selectedIndex].address = e.target.value; setShops(temp) }} />
                            <label >Latitude</label>
                            <br />
                            <input type="Text" className="form-control" placeholder="Enter Latitude" value={shops.length > 0 && shops[selectedIndex].latitude} onChange={(e) => { let temp = [...shops]; temp[selectedIndex].latitude = e.target.value; setShops(temp) }} />
                            <label >Longitude</label>
                            <input type="Text" className="form-control" placeholder="Enter Longitude" value={shops.length > 0 && shops[selectedIndex].longitude} onChange={(e) => { let temp = [...shops]; temp[selectedIndex].longitude = e.target.value; setShops(temp) }} />
                        </div>
                        <button type="submit" class="btn btn-primary">Edit</button>

                    </form>

                </ReactModal>

                <ReactModal isOpen={showAddModal}>

                    <div className="text-right">
                        <h3 className='xInmodal' onClick={() => setShowAddModal(false)}>X</h3>
                    </div>
                    <h1>Add Shop</h1>

                    <br />
                    <br />
                    <Formik
                        initialValues={{ name: '', address: '', latitude: '', longitude: '' }}
                        validate={values => {
                            const errors = {};
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            addData(values);
                            // setTimeout(() => {
                            //     alert(JSON.stringify(values, null, 2));
                            //     setSubmitting(false);
                            // }, 400);
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            /* and other goodies */
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label >Name</label>
                                    <br />
                                    <input type="Text" className="form-control" placeholder="Enter Name" name='name'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.name}
                                    />

                                    <label >Address</label>
                                    <br />
                                    <input type="Text" className="form-control" placeholder="Enter Address" name='address'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.address}
                                    />
                                    <label >Latitude</label>
                                    <br />
                                    <input type="Text" className="form-control" placeholder="Enter Latitude" name='latitude'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.latitude}
                                    />
                                    <label >Longitude</label>
                                    <input type="Text" className="form-control" placeholder="Enter Longitude" name='longitude'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.longitude}
                                    />
                                </div>
                                <button type="submit" className="btn btn-Success">
                                    Add
                                </button>
                            </form>
                        )}
                    </Formik>
                </ReactModal>
                <ConformationModal />
                <ToastContainer position="bottom-right" />
            </div>

        </div>
    );
}
