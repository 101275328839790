import React, { useContext, useState, useEffect } from 'react';
import { appContext } from '../context/ContextProvider';
import ConformationModal from '../components/ConformationModal'
import 'react-toastify/dist/ReactToastify.css';
import ReactModal from 'react-modal';
import { baseUrl } from '../utils/Constants';
import { ToastContainer, toast } from 'react-toastify';
import _ from "lodash";
import { Formik, Field } from 'formik';
import moment from 'moment';

function AppUsers(props) {

    const { user, permissions } = useContext(appContext);
    const [users, setUsers] = useState([]);
    const [dependencies, setDependencies] = useState({});
    const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [paginationData, setPaginationData] = useState({
        current_page: 0,
        first_page_url: "http://app_url/api/admin_doctors?page=1",
        from: 0,
        last_page: 0,
        last_page_url: "http://app_url/api/admin_doctors?page=2",
        next_page_url: null,
        path: "http://app_url/api/admin_doctors",
        per_page: parseInt("10"),
        prev_page_url: "http://app_url/api/admin_doctors?page=1",
        to: 0,
        total: 0
    });

    const getPageUsers = (page) => {
        toast("Fetching users!");

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
        };

        fetch(`${baseUrl}/admin_users?s=user_category&q=app_user&paginated=true&limit=50&relations=packages&page=${page}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status) {
                    setUsers(result.data.data);
                    setPaginationData({
                        current_page: result.data.current_page,
                        first_page_url: result.data.first_page_url,
                        from: result.data.from,
                        last_page: result.data.last_page,
                        last_page_url: result.data.last_page_url,
                        next_page_url: result.data.next_page_url,
                        path: result.data.path,
                        per_page: result.data.per_page,
                        prev_page_url: result.data.prev_page_url,
                        to: result.data.to,
                        total: result.data.total
                    })
                } else {
                    alert('fetch error')
                }
            })
            .catch(error => {
                console.log('error', error);
            });
    }

    const getUsers = () => {
        toast("Fetching users!");

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
        };

        fetch(`${baseUrl}/admin_users?s=user_category&q=app_user&paginated=true&limit=50&relations=packages`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status) {
                    setUsers(result.data.data);
                    setPaginationData({
                        current_page: result.data.current_page,
                        first_page_url: result.data.first_page_url,
                        from: result.data.from,
                        last_page: result.data.last_page,
                        last_page_url: result.data.last_page_url,
                        next_page_url: result.data.next_page_url,
                        path: result.data.path,
                        per_page: result.data.per_page,
                        prev_page_url: result.data.prev_page_url,
                        to: result.data.to,
                        total: result.data.total
                    })
                } else {
                    alert('fetch error')
                }
            })
            .catch(error => {
                console.log('error', error);
            });
    }

    const getCountries = () => {
        toast("Fetching Countries!");

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
        };

        fetch(`${baseUrl}/countries?relations=cities`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status) {
                    let temp = _.cloneDeep(dependencies);
                    temp.countries = result.data
                    setDependencies(temp);
                } else {
                    alert('fetch error')
                }
            })
            .catch(error => {
                console.log('error', error);
            });
    }

    const getDependencies = () => {
        getCountries();
    }

    const init = () => {
        getUsers();
        getDependencies();
    }

    useEffect(() => {
        init();
    }, []);

    const updateData = (values) => {

        setShowEditModal(false);

        var data = {
            "first_name": values.first_name,
            "last_name": values.last_name,
            "address": values.address,
            "city": values.city,
            "country": values.country,
        };

        var requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
            redirect: 'follow'
        };

        fetch(`${baseUrl}/admin_users/${users[selectedIndex].id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result) {

                    // let temp = [...users];
                    // temp[selectedIndex] = result;

                    // setUsers(temp);
                    getPageUsers(paginationData.current_page);
                    toast("Successfully Updated!");
                } else {
                    console.log('message : ', result.message);
                }
            })
            .catch(error => console.log('error', error));


    }

    const addData = (values) => {

        setShowAddModal(false);
        var data = {
            "first_name": values.first_name,
            "last_name": values.last_name,
            "email": values.email,
            "mobile_number": values.mobile_number,
            "address": values.address,
            "country": values.country,
            "city": values.city,
        };

        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
            redirect: 'follow'
        };

        fetch(`${baseUrl}/admin_users`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result) {

                    // let temp = [...shops];
                    // temp.unshift(result.items);

                    // setUsers(temp);
                    getUsers();
                    toast("Successfully Added!");
                } else {
                    console.log('message : ', result.message);
                }
            })
            .catch(error => console.log('error', error));


    }

    const goToPreviousPage = () => {
        paginationData.prev_page_url && getPageUsers(paginationData.current_page - 1);
    }

    const goToNextPage = () => {
        paginationData.next_page_url && getPageUsers(paginationData.current_page + 1);
    }

    return (
        <div>
            <ConformationModal />
            <div>
                <div className="row">
                    <div className="col-md-12">

                        <div className='headingTopStyling' >
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>

                                <h3 className="title-5 m-b-35">App. Users</h3>
                                {/* <button type="button" class="btn btn-primary" onClick={() => setShowAddModal(true)} >Add User</button> */}
                            </div>
                            <div>

                                <h3>{paginationData.total} ({paginationData.from} - {paginationData.to})</h3>


                                <a className='btnstyling' href="#" onClick={goToPreviousPage} >&laquo;</a>
                                <a className='btnstyling' href="#" onClick={goToNextPage} >&raquo;</a>


                            </div>

                        </div>

                        <div className="table-data__tool">

                        </div>
                        <div className="table-responsive table-responsive-data2">
                            <table className="table table-data2">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Phone</th>
                                        <th>Address</th>
                                        <th>Country</th>
                                        <th>City</th>
                                        <th>Subscribed</th>
                                        <th>Created At</th>
                                        <th>Modified At</th>
                                        {
                                            permissions.includes("members-edit") &&
                                            <>
                                                <th className='text-center'>Actions</th>
                                            </>
                                        }


                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        users.map((item, index) => {
                                            return (

                                                <tr className="tr-shadow" key={item.id} >
                                                    <td>
                                                        <span className="status--process">{item.first_name} {item.last_name}</span>
                                                    </td>
                                                    <td>
                                                        <span className="status--process">{item.email}</span>
                                                    </td>
                                                    <td>
                                                        <span className="status--process">{item.mobile_number}</span>
                                                    </td>
                                                    <td>
                                                        <span className="status--process">{item.address}</span>
                                                    </td>
                                                    <td>
                                                        <span className="status--process">{item.country}</span>
                                                    </td>
                                                    <td>
                                                        <span className="status--process">{item.city}</span>
                                                    </td>
                                                    <td>
                                                        <span className="status--process">{item.packages.length > 0 ? (
                                                            <>
                                                                                                        <small>
                                                <span class="badge badge-success mt-1">Subscribed</span>
                                            </small>
                                                            </>
                                                        ) : 
                                                        (
                                                            <>
                                                                                                        <small>
                                                <span class="badge badge-danger mt-1">No</span>
                                            </small>
                                                            </>
                                                        ) 
                                                        }</span>
                                                    </td>
                                                    <td>
                                                        <span className="status--process">{moment(item.created_at).format('DD MMM, YYYY')}</span>
                                                    </td>
                                                    <td>
                                                        <span className="status--process">{moment(item.updated_at).format('DD MMM, YYYY')}</span>
                                                    </td>
                                                    
                                                    <td className='d-flex justify-content-center'>
                                                        <div className="table-data-feature">
                                                        <button className="item" data-toggle="tooltip" data-placement="top" title="Check Subscriptions" onClick={() => { setShowSubscriptionModal(true); setSelectedIndex(index) }} >
                                                                        <i className="zmdi zmdi-case-check" />
                                                                    </button>
                                                            {
                                                                permissions.includes("members-edit") &&
                                                                <>
                                                                    <button className="item" data-toggle="tooltip" data-placement="top" title="Edit" onClick={() => { setShowEditModal(true); setSelectedIndex(index) }} >
                                                                        <i className="zmdi zmdi-edit" />
                                                                    </button>
                                                                </>
                                                            }
                                                            {/* <button className="item" data-toggle="tooltip" data-placement="top" title="Delete">
                                  <i className="zmdi zmdi-delete" data-toggle="modal" data-target="#smallmodal" />
                                </button> */}

                                                        </div>
                                                    </td>
                                                </tr>


                                            )

                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <ReactModal isOpen={showEditModal}>

                    <div className="text-right">
                        <h3 className='xInmodal' onClick={() => setShowEditModal(false)}>X</h3>
                    </div>
                    <h1>Edit App. User</h1>

                    <br />
                    <br />
                    <Formik
                        initialValues={{ first_name: users.length > 0 && users[selectedIndex].first_name,
                                        last_name: users.length > 0 && users[selectedIndex].last_name,
                                        address: users.length > 0 && users[selectedIndex].address,
                                        country: users.length > 0 && users[selectedIndex].country,
                                        city: users.length > 0 && users[selectedIndex].city,
                        }}
                        validate={values => {
                            const errors = {};
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            updateData(values);
                            // setTimeout(() => {
                            //     alert(JSON.stringify(values, null, 2));
                            //     setSubmitting(false);
                            // }, 400);
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            /* and other goodies */
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label >First Name</label>
                                    <br />
                                    <input type="Text" className="form-control" placeholder="Enter Name" name='first_name'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.first_name}
                                    />
                                    <label >Last Name</label>
                                    <br />
                                    <input type="Text" className="form-control" placeholder="Enter Name" name='last_name'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.last_name}
                                    />
                                    <label >Address</label>
                                    <br />
                                    <input type="Text" className="form-control" placeholder="Enter Name" name='address'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.address}
                                    />
                                    <label >Country</label>
                                    <br />
                                    <Field as="select" name="country" class='form-control' >
                                    <option value={''}>{''}</option>
                                    {
                                            dependencies.countries && dependencies.countries.map((item, index) => {
                                                return (
                                                    <option value={item.Country_Name}>{item.Country_Name}</option>
                                                )

                                            })
                                        }
                                    </Field>
                                    <label >City</label>
                                    <br />
                                    <Field as="select" name="city" class='form-control' >
                                    <option value={''}>{''}</option>
                                    {
                                            values.country != '' 
                                            && _.filter(dependencies.countries, function(o) { return o.Country_Name == values.country; }).length > 0 
                                            && _.filter(dependencies.countries, function(o) { return o.Country_Name == values.country; })[0].cities.map((item, index) => {
                                                return (
                                                    <option value={item.SE_Name}>{item.SE_Name}</option>
                                                )

                                            })
                                        }
                                    </Field>
                                </div>
                                <button type="submit" className="btn btn-primary">
                                    Edit
                                </button>
                            </form>
                        )}
                    </Formik>

{/* 
                    <form onSubmit={updateData} >
                        <div className="form-group">
                            <label >Name</label>
                            <br />
                            <input type="Text" className="form-control" placeholder="Enter Name" value={users.length > 0 && users[selectedIndex].name} onChange={(e) => { let temp = [...users]; temp[selectedIndex].name = e.target.value; setUsers(temp) }} />
                        </div>
                        <button type="submit" class="btn btn-primary">Edit</button>

                    </form> */}

                </ReactModal>

                <ReactModal isOpen={showAddModal}>

                    <div className="text-right">
                        <h3 className='xInmodal' onClick={() => setShowAddModal(false)}>X</h3>
                    </div>
                    <h1>Add User</h1>

                    <br />
                    <br />
                    <Formik
                        initialValues={{ first_name: '',
                            last_name: '',
                            email: '',
                            mobile_number: '',
                            address: '',
                            country: '',
                            city: '',
                         }}
                        validate={values => {
                            const errors = {};
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            addData(values);
                            // setTimeout(() => {
                            //     alert(JSON.stringify(values, null, 2));
                            //     setSubmitting(false);
                            // }, 400);
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            /* and other goodies */
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label >First Name</label>
                                    <br />
                                    <input type="Text" className="form-control" placeholder="Enter First Name" name='first_name'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.first_name}
                                    />
                                    <label >Last Name</label>
                                    <br />
                                    <input type="Text" className="form-control" placeholder="Enter Last Name" name='last_name'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.last_name}
                                    />
                                    <label >Email</label>
                                    <br />
                                    <input type="Text" className="form-control" placeholder="Enter Email" name='email'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                    />
                                    <label >Phone</label>
                                    <br />
                                    <input type="Text" className="form-control" placeholder="Enter Phone Number" name='mobile_number'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.mobile_number}
                                    />
                                    <label >Address</label>
                                    <br />
                                    <input type="Text" className="form-control" placeholder="Enter Address" name='address'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.address}
                                    />
                                    <label >Country</label>
                                    <br />
                                    <Field as="select" name="country" class='form-control' >
                                    <option value={''}>{''}</option>
                                    {
                                            dependencies.countries && dependencies.countries.map((item, index) => {
                                                return (
                                                    <option value={item.Country_Name}>{item.Country_Name}</option>
                                                )

                                            })
                                        }
                                    </Field>
                                    <label >City</label>
                                    <br />
                                    <Field as="select" name="city" class='form-control' >
                                    <option value={''}>{''}</option>
                                    {
                                            values.country != '' 
                                            && _.filter(dependencies.countries, function(o) { return o.Country_Name == values.country; }).length > 0 
                                            && _.filter(dependencies.countries, function(o) { return o.Country_Name == values.country; })[0].cities.map((item, index) => {
                                                return (
                                                    <option value={item.SE_Name}>{item.SE_Name}</option>
                                                )

                                            })
                                        }
                                    </Field>
                                </div>
                                <button type="submit" className="btn btn-primary">
                                    Add
                                </button>
                            </form>
                        )}
                    </Formik>
                </ReactModal>
                <ReactModal isOpen={showSubscriptionModal}>

                    <div className="text-right">
                        <h3 className='xInmodal' onClick={() => setShowSubscriptionModal(false)}>X</h3>
                    </div>
                    <h1>User Subscriptions</h1>
                        <br/>
                        {users.length > 0 && users[selectedIndex].packages && users[selectedIndex].packages.length > 0 && users[selectedIndex].packages.map((item, key)=> {
                            return(
                                <>
                                {/* <h3>{item.name}</h3>
                                <h4>Subscribed At : </h4>
                                <h5>{moment(item.pivot.created_at).format('DD MMM, YYYY')}</h5>
                                <h4>Expire At : </h4>
                                <h5>{moment(item.pivot.expire_at).format('DD MMM, YYYY')}</h5> */}
                                <br/>
                                <div class="card">
                                    <div class="card-header">
                                        <strong class="card-title">{item.name}
                                        </strong>
                                    </div>
                                    <div class="card-body">
                                    <h5>Subscribed At </h5>
                                        <p class="card-text">{moment(item.pivot.created_at).format('DD MMM, YYYY')}
                                        </p>
                                    <h5>Expire At </h5>
                                        <p class="card-text">{moment(item.pivot.expire_at).format('DD MMM, YYYY')}
                                        </p>
                                    </div>
                                </div>
                                </>
                            )
                        })}
                        
                    <br />
                   
                </ReactModal>
                <ConformationModal />
                <ToastContainer position="bottom-right" />
            </div>

        </div>
    );
}

export default AppUsers;