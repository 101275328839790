import React, { useContext, useState, useEffect } from 'react';
import { appContext } from '../context/ContextProvider';
import ConformationModal from '../components/ConformationModal'
import 'react-toastify/dist/ReactToastify.css';
import ReactModal from 'react-modal';
import { baseUrl } from '../utils/Constants';
import { ToastContainer, toast } from 'react-toastify';
import _ from "lodash";
import { Formik, Field } from 'formik';
import moment from 'moment';

function CardsCategories(props) {

    const { user, permissions } = useContext(appContext);
    const [cardCategories, setCardCategories] = useState([]);
    const [dependencies, setDependencies] = useState({});
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [paginationData, setPaginationData] = useState({
        current_page: 0,
        first_page_url: "http://app_url/api/admin_doctors?page=1",
        from: 0,
        last_page: 0,
        last_page_url: "http://app_url/api/admin_doctors?page=2",
        next_page_url: null,
        path: "http://app_url/api/admin_doctors",
        per_page: parseInt("10"),
        prev_page_url: "http://app_url/api/admin_doctors?page=1",
        to: 0,
        total: 0
    });

    const getPageCardCategories = (page) => {
        toast("Fetching cardCategories!");

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
        };

        fetch(`${baseUrl}/categories?relations=language&paginated=true&limit=10&page=${page}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status) {
                    setCardCategories(result.data.data);
                    setPaginationData({
                        current_page: result.data.current_page,
                        first_page_url: result.data.first_page_url,
                        from: result.data.from,
                        last_page: result.data.last_page,
                        last_page_url: result.data.last_page_url,
                        next_page_url: result.data.next_page_url,
                        path: result.data.path,
                        per_page: result.data.per_page,
                        prev_page_url: result.data.prev_page_url,
                        to: result.data.to,
                        total: result.data.total
                    })
                } else {
                    alert('fetch error')
                }
            })
            .catch(error => {
                console.log('error', error);
            });
    }

    const getCardCategories = () => {
        toast("Fetching cardCategories!");

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
        };

        fetch(`${baseUrl}/categories?relations=language&paginated=true&limit=10`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status) {
                    setCardCategories(result.data.data);
                    setPaginationData({
                        current_page: result.data.current_page,
                        first_page_url: result.data.first_page_url,
                        from: result.data.from,
                        last_page: result.data.last_page,
                        last_page_url: result.data.last_page_url,
                        next_page_url: result.data.next_page_url,
                        path: result.data.path,
                        per_page: result.data.per_page,
                        prev_page_url: result.data.prev_page_url,
                        to: result.data.to,
                        total: result.data.total
                    })
                } else {
                    alert('fetch error')
                }
            })
            .catch(error => {
                console.log('error', error);
            });
    }

    const getLanguages = () => {
        toast("Fetching languages!");

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
        };

        fetch(`${baseUrl}/languages`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status) {
                    let temp = _.cloneDeep(dependencies);
                    temp.languages = result.data
                    setDependencies(temp);
                } else {
                    alert('fetch error')
                }
            })
            .catch(error => {
                console.log('error', error);
            });
    }

    const getDependencies = () => {
        getLanguages();
    }

    const init = () => {
        getCardCategories();
        getDependencies();
    }

    useEffect(() => {
        init();
    }, []);

    const getBool = (val) => {
        return !!JSON.parse(String(val).toLowerCase());
    }
    
    const updateData = async (values) => {

        setShowEditModal(false);

        var data = {
            "name": values.name,
            "language_id": values.language_id,
            "image": values.image_file != '' ? await convertToBase64(values.image_file) : '',
            "is_paid": getBool(values.is_paid),
        };

        var requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
            redirect: 'follow'
        };

        fetch(`${baseUrl}/categories/${cardCategories[selectedIndex].id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result) {

                    // let temp = [...cardCategories];
                    // temp[selectedIndex] = result;

                    // setCardCategories(temp);
                    getPageCardCategories(paginationData.current_page);
                    toast("Successfully Updated!");
                } else {
                    console.log('message : ', result.message);
                }
            })
            .catch(error => console.log('error', error));


    }

    const convertToBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const addData = async (values) => {

        setShowAddModal(false);
        var data = {
            "name": values.name,
            "language_id": values.language_id,
            "image": await convertToBase64(values.image_file),
            "is_paid": getBool(values.is_paid),
        };

        console.log('data', data);

        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
            redirect: 'follow'
        };

        fetch(`${baseUrl}/categories`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result) {

                    // let temp = [...shops];
                    // temp.unshift(result.items);

                    // setCardCategories(temp);
                    getCardCategories();
                    toast("Successfully Added!");
                } else {
                    console.log('message : ', result.message);
                }
            })
            .catch(error => console.log('error', error));


    }

    const goToPreviousPage = () => {
        paginationData.prev_page_url && getPageCardCategories(paginationData.current_page - 1);
    }

    const goToNextPage = () => {
        paginationData.next_page_url && getPageCardCategories(paginationData.current_page + 1);
    }

    return (
        <div>
            <ConformationModal />
            <div>
                <div className="row">
                    <div className="col-md-12">

                        <div className='headingTopStyling' >
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>

                                <h3 className="title-5 m-b-35">Cards Categories</h3>
                                
                            </div>
                            <div>
                           
                                <h3>{paginationData.total} ({paginationData.from} - {paginationData.to})</h3>


                                <a className='btnstyling' href="#" onClick={goToPreviousPage} >&laquo;</a>
                                <a className='btnstyling' href="#" onClick={goToNextPage} >&raquo;</a>


                            </div>

                        </div>
                        <button type="button" class="btn btn-success" onClick={() => setShowAddModal(true)} >Add Category</button>

                        <div className="table-data__tool">

                        </div>
                        <div className="table-responsive table-responsive-data2">
                            <table className="table table-data2">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Image</th>
                                        <th>Language</th>
                                        <th>Paid</th>
                                        <th>Created At</th>
                                        <th>Updated At</th>
                                        {
                                            permissions.includes("categories-edit") &&
                                            <>
                                                <th className='text-center'>Actions</th>
                                            </>
                                        }


                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        cardCategories.map((item, index) => {
                                            return (

                                                <tr className="tr-shadow" key={item.id} >
                                                    <td>
                                                        <span className="status--process">{item.name}</span>
                                                    </td>
                                                    <td>
                                                        <img src={item.current_image_url ? item.current_image_url : ''} width="150" height="150" />
                                                    </td>
                                                    <td>
                                                        <span className="status--process">{item.language && item.language != null && item.language.name}</span>
                                                    </td>
                                                    <td>
                                                        <span className="status--process">{(item.is_paid && item.is_paid != null) ? 'Paid' : 'Free'}</span>
                                                    </td>
                                                    <td>
                                                        <span className="status--process">{moment(item.created_at).format('DD MMM, YYYY')}</span>
                                                    </td>
                                                    <td>
                                                        <span className="status--process">{moment(item.updated_at).format('DD MMM, YYYY')}</span>
                                                    </td>
                                                    <td className='d-flex justify-content-center'>
                                                        <div className="table-data-feature">
                                                            {
                                                                permissions.includes("categories-edit") &&
                                                                <>
                                                                    <button className="item" data-toggle="tooltip" data-placement="top" title="Edit" onClick={() => { setShowEditModal(true); setSelectedIndex(index) }} >
                                                                        <i className="zmdi zmdi-edit" />
                                                                    </button>
                                                                </>
                                                            }
                                                            {/* <button className="item" data-toggle="tooltip" data-placement="top" title="Delete">
                                  <i className="zmdi zmdi-delete" data-toggle="modal" data-target="#smallmodal" />
                                </button> */}

                                                        </div>
                                                    </td>
                                                </tr>


                                            )

                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <ReactModal isOpen={showEditModal}>

                    <div className="text-right">
                        <h3 className='xInmodal' onClick={() => setShowEditModal(false)}>X</h3>
                    </div>
                    <h1 className='topmodalH'>Edit Card Category</h1>

                    <br />
                    <br />
                    <Formik
                        initialValues={{ name: cardCategories.length > 0 && cardCategories[selectedIndex].name, 
                            language_id: cardCategories.length > 0 && cardCategories[selectedIndex].language.id,
                            image_url: cardCategories.length > 0 && cardCategories[selectedIndex].current_image_url ? cardCategories[selectedIndex].current_image_url : '', 
                            image_file:'',
                            is_paid: cardCategories.length > 0 && cardCategories[selectedIndex].is_paid
                        }}
                        validate={values => {
                            const errors = {};
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            updateData(values);
                            // setTimeout(() => {
                            //     alert(JSON.stringify(values, null, 2));
                            //     setSubmitting(false);
                            // }, 400);
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue
                            /* and other goodies */
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                   
                                   <div className='row'>
<div className='col'>
<label className='labelName' >Name</label>
                                    <br />
                                    <input type="Text" className="form-control" placeholder="Enter Name" name='name'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.name}
                                    />
                                   
                                    <br />
                                    <label className='labelName' >Language</label>
                                    <br />
                                    <Field as="select" name="language_id" class='form-control' >
                                    {
                                            dependencies.languages && dependencies.languages.map((item, index) => {
                                                return (
                                                    <option value={item.id}>{item.name}</option>
                                                )

                                            })
                                        }
                                    </Field>
                                    <label className='labelName' >Paid</label>
                                    <br />
                                    <span className="status--process">
                                    <label class="switch switch-3d switch-success mr-3">
                                        <input type="checkbox" class="switch-input" defaultChecked={values.is_paid} onChange={(e) => setFieldValue('is_paid', !values.is_paid)} />
                                        <span class="switch-label"></span>
                                        <span class="switch-handle"></span>
                                    </label>
                                    </span>
                                </div>



<div className='col'>

<label className='labelName' >Image</label>
                                    <br />
                                    <input id="file" name="image_file" type="file" onChange={(event) => {
                                        setFieldValue("image_file", event.currentTarget.files[0]);
                                        setFieldValue("image_url", URL.createObjectURL(event.currentTarget.files[0]) );
                                    }} />
                                    <br />
                                    <img src={values.image_url} width="200" height="200" />


                                    <button type="submit" className="btn btn-success btn-lg">
                                    Edit
                                </button>

</div>

                                   </div>
                                   </div>
                                
                            </form>
                        )}
                    </Formik>

{/* 
                    <form onSubmit={updateData} >
                        <div className="form-group">
                            <label >Name</label>
                            <br />
                            <input type="Text" className="form-control" placeholder="Enter Name" value={cardCategories.length > 0 && cardCategories[selectedIndex].name} onChange={(e) => { let temp = [...cardCategories]; temp[selectedIndex].name = e.target.value; setCardCategories(temp) }} />
                        </div>
                        <button type="submit" class="btn btn-primary">Edit</button>

                    </form> */}

                </ReactModal>

                <ReactModal isOpen={showAddModal}>

                    <div className="text-right">
                        <h3 className='xInmodal' onClick={() => setShowAddModal(false)}>X</h3>
                    </div>
                    <h1 className='addCardCategory' >Add Card Category</h1>

                    <br />
                    <br />
                    <Formik
                        initialValues={{ name: '', image_url: '', image_file:'', language_id: 1, is_paid:false }}
                        validate={values => {
                            const errors = {};
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            addData(values);
                            // setTimeout(() => {
                            //     alert(JSON.stringify(values, null, 2));
                            //     setSubmitting(false);
                            // }, 400);
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue
                            /* and other goodies */
                        }) => (
                            <form onSubmit={handleSubmit}>
                              
                              
                               <div className="form-group" >
                               <div className='row'>
                                <div className='col' >
                                    <label className='labelName' >Name</label>
                                    <br />
                                    <input type="Text" className="form-control" placeholder="Enter Name" name='name'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.name}
                                    />
                                   
                                    <label className='labelName' >Language</label>
                                    <br />
                                    <Field as="select" name="language_id" class='form-control' >
                                    {
                                            dependencies.languages && dependencies.languages.map((item, index) => {
                                                return (
                                                    <option value={item.id}>{item.name}</option>
                                                )

                                            })
                                        }
                                    </Field>
                                    <label className='labelName' >Paid</label>
                                    <br />
                                    <span className="status--process">
                                    <label class="switch switch-3d switch-success mr-3">
                                        <input type="checkbox" class="switch-input" defaultChecked={values.is_paid} onChange={(e) => setFieldValue('is_paid', !values.is_paid)} />
                                        <span class="switch-label"></span>
                                        <span class="switch-handle"></span>
                                    </label>
                                    </span>
                                </div>
                            <div className='col'>
                            <label >Image</label>
                                    <br />
                                    <input id="file" name="image_file" type="file" onChange={(event) => {
                                        setFieldValue("image_file", event.currentTarget.files[0]);
                                        setFieldValue("image_url", URL.createObjectURL(event.currentTarget.files[0]) );
                                    }} />
                                    <br />
                                    <img src={values.image_url} width="200" height="200" />
                                    <br />
                                    <button type="submit" class="btn btn-success btn-lg">
                                    Add
                                </button>
                            </div>
                            </div>

                              
                                </div>
                            </form>
                        )}
                    </Formik>
                </ReactModal>
                <ConformationModal />
                <ToastContainer position="bottom-right" />
            </div>

        </div>
    );
}

export default CardsCategories;