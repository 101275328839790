import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import ConformationModal from '../../components/ConformationModal';
import 'react-toastify/dist/ReactToastify.css';
import ReactModal from 'react-modal';
import { baseUrl } from '../../utils/Constants';

function Roles(props) {
  const [roles, setRoles] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const getRoles = () => {
    toast("Fetching roles!");

    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
    };

    fetch(`${baseUrl}/roles`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status) {
          setRoles(result.data);
        } else {
          alert('fetch error')
        }
      })
      .catch(error => {
        console.log('error', error);
      });
  }

  const init = () => {
    getRoles();
  }

  useEffect(() => {
    init();
  }, []);

  const updateData = (e) => {
    e.preventDefault();
    setShowEditModal(false);
    let updatedRoleName = e.target.role.value;

    var data = { "name": updatedRoleName };

    var requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
      redirect: 'follow'
    };

    fetch(`${baseUrl}/roles/${roles[selectedIndex].id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result) {

          let temp = [...roles];
          temp[selectedIndex] = result;

          setRoles(temp);

        } else {
          console.log('message : ', result.message);
        }
      })
      .catch(error => console.log('error', error));


  }

  return (
    <div>
      <div className="row">
        <div className="col-md-6">

          <div className='headingTopStyling' >
            <h3 className="title-5 m-b-35">Roles </h3>
            <button type="button" class="btn btn-success">Add Roles</button>
          </div>


          <div className="table-data__tool">

          </div>
          <div className="table-responsive table-responsive-data2">
            <table className="table table-data2">
              <thead>
                <tr>
                  <th>Name</th>
                  <th className='text-center'>Actions</th>
                </tr>
              </thead>
              <tbody>
                {
                  roles.map((item, index) => {
                    return (

                      <tr className="tr-shadow" key={item.id} >
                        <td>
                          <span className="status--process">{item.name}</span>
                        </td>
                        <td className='d-flex justify-content-center'>
                          <div className="table-data-feature">
                            <button className="item" data-toggle="tooltip" data-placement="top" title="Edit" onClick={() => { setShowEditModal(true); setSelectedIndex(index) }} >
                              <i className="zmdi zmdi-edit" />
                            </button>
                            {/* <button className="item" data-toggle="tooltip" data-placement="top" title="Delete">
                              <i className="zmdi zmdi-delete" data-toggle="modal" data-target="#smallmodal" />
                            </button> */}
                            <button className="item" data-toggle="tooltip" data-placement="top" title="Add">
                              <i className="zmdi zmdi-plus" data-toggle="modal" data-target="#RolesModalAdd" />
                            </button>
                          </div>
                        </td>
                      </tr>


                    )

                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <ReactModal isOpen={showEditModal}>

        <div className="text-right">
          <h3 className='xInmodal' onClick={() => setShowEditModal(false)}>X</h3>
        </div>
        <h1 className='topmodalH' >Edit Roles</h1>

        <br />
        <br />


        <form onSubmit={updateData} >
          <div className="form-group">

            <div className='row'>
              <div className='col'>
                <label >Name</label>
                <br />
                <input type="Text" className="form-control" name='role' value={roles.length > 0 && roles[selectedIndex].name} onChange={(e) => { let temp = [...roles]; temp[selectedIndex].name = e.target.value; setRoles(temp) }} placeholder="Enter Name" />
                <br/><br/>
              <button type="submit" class="btn btn-success">Edit</button>
              </div>
             

              <div className='col'>

              </div>
            </div>

          </div>
        </form>

      </ReactModal>





      <ConformationModal />
      <ToastContainer position="bottom-right" />
    </div>
  );
}

export default Roles;