import React, { createContext, useState } from 'react';

export const appContext = createContext({});

function ContextProvider({ children }) {

    const [user, setUser] = useState(null);
    const [permissions, setPermissions] = useState([]);
    const [token, setToken] = useState(false);

    return (
        <appContext.Provider
            value={{
                token,
                setToken,
                user,
                setUser,
                permissions,
                setPermissions
            }}>
            {children}
        </appContext.Provider>
    );
}

export default ContextProvider;