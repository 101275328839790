import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { appContext } from "../../context/ContextProvider";
import { baseUrl } from "../../utils/Constants";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import _ from 'lodash';

import 'react-toastify/dist/ReactToastify.css';

function Register(props) {

    let navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');

    const onSubmitForm = (e) => {
        e.preventDefault();
        navigate("/email-verification", {
            state: { userEmail: email }
        });

        if (email != '' && password != '' && name != '') {
            var formdata = new FormData();
            formdata.append("email", email);
            formdata.append("password", password);
            formdata.append("name", name);
            formdata.append("user_category", 'admin');

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${baseUrl}/register`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (_.truthy(result.status)) {
                        toast("Verification Code Sent Successfully!");
                        navigate("/email-verification", {
                            state: { userEmail: email, userId: result.user.id }
                        });
                    } else {
                        console.log('message : ', result.message);
                    }
                })
                .catch(error => console.log('error', error));
        } else {
            toast.error("Please Fill All Fields!");
        }

    }
    return (
        <div class="login-wrap">
            <div class="login-content">
                <div class="login-logo">
                    <a href="#">
                        <img src="images/icon/logo.png" alt="CoolAdmin" />
                    </a>
                </div>
                <div class="login-form">
                    <form onSubmit={(e) => onSubmitForm(e)}>
                        <div class="form-group">
                            <label>Name</label>
                            <input class="au-input au-input--full" type="text" name="name" placeholder="Doctring Admin" value={name} onChange={(e) => setName(e.target.value)} />
                        </div>
                        <div class="form-group">
                            <label>Email Address</label>
                            <input class="au-input au-input--full" type="email" name="email" value={email} placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div class="form-group">
                            <label>Password</label>
                            <input class="au-input au-input--full" type="password" name="password" value={password} placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                        </div>
                        <button class="au-btn au-btn--block au-btn--green m-b-20" >register</button>
                    </form>
                    <div class="register-link">
                        <p>
                            Already have account?
                            <Link to={'/login'}>Sign In</Link>
                        </p>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}

export default Register;