import React, { useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { appContext } from "../context/ContextProvider";

function SideBar(props) {
    const { user, setUser, setToken, setPermissions, permissions } = useContext(appContext);

    return (
        <aside className="menu-sidebar d-none d-lg-block">
            <div className="logo">
                <a href="#">
                    <img src="images/icon/logo.png" alt="Cool Admin" />
                </a>
            </div>
            <div className="menu-sidebar__content js-scrollbar1">
                <nav className="navbar-sidebar">
                    <ul className="list-unstyled navbar__list">
                        {
                            user.roles && user.roles.length > 0 &&
                            <>
                                <li className="has-sub">
                                    <NavLink className="js-arrow" to={'/dashboard'} >
                                        <i className="fas fa-tachometer-alt"></i>Dashboard
                                    </NavLink>
                                </li>
                                {
                                    permissions.includes("categories-view") &&
                                    <>
                                        <li>
                                            <NavLink to='/cards_categories' >
                                                <i className="fas fa-cubes"></i>Categories
                                            </NavLink>
                                        </li>
                                    </>
                                }
                                {
                                    permissions.includes("categories-view") &&
                                    <>
                                        <li>
                                            <NavLink to='/sub_card_categories' >
                                                <i className="fas fa-chart-bar"></i>Sub Categories
                                            </NavLink>
                                        </li>
                                    </>
                                }
                                {
                                    permissions.includes("languages-view") &&
                                    <>
                                        <li>
                                            <NavLink to='/languages' >
                                                <i className="fas fa-language"></i>Languages
                                            </NavLink>
                                        </li>
                                    </>
                                }
                                {
                                    permissions.includes("phrases-view") &&
                                    <>
                                        <li>
                                            <NavLink to='/phrases' >
                                                <i className="fas fa-sort-alpha-asc"></i>Phrases
                                            </NavLink>
                                        </li>
                                    </>
                                }
                                {
                                    permissions.includes("packages-view") &&
                                    <>
                                        <li>
                                            <NavLink to='/packages' >
                                                <i className="fas fa-gift"></i>Packages
                                            </NavLink>
                                        </li>
                                    </>
                                }
                                {
                                    permissions.includes("transactions-view") &&
                                    <>
                                        <li>
                                            <NavLink to='/transactions' >
                                                <i className="fas fa-paper-plane"></i>Transactions
                                            </NavLink>
                                        </li>
                                    </>
                                }
                                {
                                    permissions.includes("members-view") &&
                                    <>
                                        <li>
                                            <NavLink to='/app_users' >
                                                <i className="fas fa-users"></i>App. Users
                                            </NavLink>
                                        </li>
                                    </>
                                }
                                {
                                    user.roles && user.roles.length > 0 && user.roles[0].name == 'Super Admin' &&
                                    <>
                                        <li>
                                            <NavLink to={'/manage-admin-users'} >
                                                <i className="fas fa-user-secret"></i>Manage Users</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={'/acl'} >
                                                <i className="fas fa-window-restore"></i>Access Control List</NavLink>
                                            <ul className="list-unstyled js-sub-list">
                                                <li>
                                                    <NavLink to={'/roles'} >Roles</NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to={'/permissions'} >Permissions</NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to={'/roles-permissions'} >Roles & Permissions</NavLink>
                                                </li>
                                            </ul>
                                        </li>
                                    </>
                                }
                            </>
                        }

                        <li>
                            <a href="#" onClick={(e) => { e.preventDefault(); localStorage.clear(); setUser(null); }}>Logout</a>
                        </li>

                    </ul>
                </nav>
            </div>

        </aside>
    );
}

export default SideBar;