import React from 'react';

import Header from '../components/Header';
import HeaderMobile from '../components/HeaderMobile';
import SideBar from '../components/SideBar';
import Content from '../components/Content';

import Router from "../router/Router";
function MainFlow(props) {
    return (
        <div className='page-wrapper' >
            {/* <HeaderMobile /> */}
            <SideBar />
            <div className='page-container' >
                {/* <Header /> */}
                <div class="main-content">
                    <div class="section__content section__content--p30">
                        <div class="container-fluid">
                            <Router />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MainFlow;